<template>
  <div class="detail-box">
    <div class="cate-list">
      <div v-for="(item, index) in cateList" :key="index" class="cate-item" @click="router('/nft/list', { cate_id: item.id })">
        <img :src="item.logo" class="cate-logo">
      </div>
    </div>
    <div class="detail-main" v-if="Object.keys(productInfo).length > 0">
      <div class="cate-info">
        <img :src="productInfo.cate_logo" class="cate-logo">
      </div>
      <div class="detail-info">
        <div class="detail-img">
          <img :src="productInfo.img">
        </div>
        <div class="detail-info-right">
          <div class="detail-title">{{ productInfo.name }}</div>
          <div class="detail-token">TokenID：{{ productInfo.hash }}</div>
          <div class="detail-money">
            <span>价格：¥ </span>
            {{ productInfo.money }}
          </div>
        </div>
      </div>
      <div class="detail-content">
        <div class="deatil-spec">
          <span v-for="(item, index) in productInfo.spec" :key="index">{{ item.title }}：{{ item.value }}</span>
        </div>
        <div class="deatil-spec">
          <span v-for="(item, index) in productInfo.attribute" :key="index">{{ item.title }}：{{ item.value }}</span>
        </div>
        <div class="detail-desc">
          <div class="detail-desc-bg">
            <img :src="productInfo.cate_bg">
            藏品详情
          </div>
          <div class="detail-desc-content" v-html="productInfo.introduction"></div>
          <div class="detail-desc-bg">
            <img :src="productInfo.cate_bg">
            作者简介
          </div>
          <div class="detail-desc-content" v-html="productInfo.author_introduction"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nft-detail",
  data() {
    return {
      cateList: [],
      productInfo: { }
    }
  },
  mounted() {
    this.getCateList();
    this.getProductInfo();
  },
  methods: {
    // 获取分类列表
    getCateList() {
      this.$api.nft_product.getCateList().then((res) => {
        this.cateList = res.data
      });
    },
    // 获取藏品信息
    getProductInfo() {
      if (!this.$route.query.id) {
        this.$message({
          message: "藏品信息不存在",
          type: "danger",
        });
        this.$router.back()
      }
      else {
        this.$api.nft_product.getProductInfo({ id: this.$route.query.id }).then((res) => {
          this.productInfo = res.data
        });
      }
    },
    // 跳转页面
    router(url, query) {
      this.$router.push({
        path: url,
        query: query
      });
    },
  }
}
</script>

<style lang="less" scoped>
.detail-box {
  width:1200px;
  margin:100px auto;
  display: flex;
  flex-direction: row;
  .cate-list {
    flex: 1;
    margin-top:100px;
    .cate-item {
      margin-bottom: 20px;
      cursor: pointer;
      .cate-logo {
        width:160px;
        height:80px;
      }
    }
  }
  .detail-main {
    flex: 2;
    margin-left:100px;
    .cate-info {
      background: url(../../assets/nft/cate-border.png) no-repeat;
      background-size: 99%;
      height:40px;
      width:550px;
      margin: auto;
      .cate-logo {
        width: 200px;
        height: 100px;
        position: relative;
        top: -50px;
      }
    }
    .detail-info {
      display: flex;
      flex-direction: row;
      margin:30px;
      .detail-img {
        background: url(../../assets/nft/product-border.png) no-repeat;
        background-size: 100% 100%;
        height:400px;
        width:400px;
        position: relative;
        margin-bottom:20px;
        img {
          position: absolute;
          height:392px;
          width:392px;
          left: 4px;
          top: 4px;
          right:4px;
          bottom:4px;
          border-radius: 18px;
        }
      }
      .detail-info-right {
        margin-left:30px;
        text-align: left;
        .detail-title {
          color:#ffffff;
          font-size: 24px;
          margin-bottom: 20px;
          font-weight: 700;
        }
        .detail-token {
          color:#999999;
          font-size:14px;
          margin-bottom: 20px;
        }
        .detail-money {
          color:#FFA200;
          font-size:23px;
          font-weight: 700;
          span {
            font-size: 14px;
          }
        }
      }
    }
    .detail-content {
      margin:30px;
      .deatil-spec {
        color:#ffffff;
        margin-bottom:10px;
        display: inline-block;
        width:800px;
        span {
          text-align: left;
          width:200px;
          display: inline-block;
          float: left;
        }
      }
      .detail-desc {
        margin:30px 50px;
        .detail-desc-bg {
          height:65px;
          width:280px;
          color:#ffffff;
          font-size:18px;
          line-height: 65px;
          text-align: center;
          position: relative;
          margin: 20px auto;
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            left:0;
            top:0;
            z-index: -1;
          }
        }
        .detail-desc-content {
          color:#ffffff;
          text-align: left;
        }
      }
    }
  }
}
</style>
